import * as React from 'react';

import baseStyles from '../base.module.css';

interface Props {
  text: string;
}

export const BookingWidgetSectionHeader = ({ text }: Props) => {
  return (
    <div className={baseStyles['base-headline']}>
      <svg
        className={baseStyles['color-main--fill']}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <rect width="32" height="32" fill="none" />
        <path d="M1.88,18.82h9.41v9.42a1.88,1.88,0,0,1-1.88,1.88H1.88A1.88,1.88,0,0,1,0,28.24V20.71A1.89,1.89,0,0,1,1.88,18.82Z" />
        <path d="M13.18,11.29h3.76a1.89,1.89,0,0,1,1.88,1.89v3.76a1.88,1.88,0,0,1-1.88,1.88H11.29V13.18A1.89,1.89,0,0,1,13.18,11.29Z" />
        <path d="M11.29,16.94v1.88H9.41A1.88,1.88,0,0,0,11.29,16.94Z" />
        <path d="M11.29,20.71V18.82h1.89A1.89,1.89,0,0,0,11.29,20.71Z" />
        <path d="M20.71,9.41h9.41A1.88,1.88,0,0,1,32,11.29v7.53a1.89,1.89,0,0,1-1.88,1.89H22.59a1.89,1.89,0,0,1-1.88-1.89V9.41Z" />
        <path d="M15.06,1.88h3.76a1.89,1.89,0,0,1,1.89,1.88V9.41H15.06a1.88,1.88,0,0,1-1.88-1.88V3.76A1.88,1.88,0,0,1,15.06,1.88Z" />
        <path d="M22.59,9.41H20.71V7.53A1.88,1.88,0,0,0,22.59,9.41Z" />
        <path d="M18.82,9.41h1.89v1.88A1.89,1.89,0,0,0,18.82,9.41Z" />
      </svg>
      <p className="base-key__text">{text}</p>
      <svg
        className={baseStyles['color-main--fill']}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <rect width="32" height="32" fill="none" />
        <path d="M1.88,18.82h9.41v9.42a1.88,1.88,0,0,1-1.88,1.88H1.88A1.88,1.88,0,0,1,0,28.24V20.71A1.89,1.89,0,0,1,1.88,18.82Z" />
        <path d="M13.18,11.29h3.76a1.89,1.89,0,0,1,1.88,1.89v3.76a1.88,1.88,0,0,1-1.88,1.88H11.29V13.18A1.89,1.89,0,0,1,13.18,11.29Z" />
        <path d="M11.29,16.94v1.88H9.41A1.88,1.88,0,0,0,11.29,16.94Z" />
        <path d="M11.29,20.71V18.82h1.89A1.89,1.89,0,0,0,11.29,20.71Z" />
        <path d="M20.71,9.41h9.41A1.88,1.88,0,0,1,32,11.29v7.53a1.89,1.89,0,0,1-1.88,1.89H22.59a1.89,1.89,0,0,1-1.88-1.89V9.41Z" />
        <path d="M15.06,1.88h3.76a1.89,1.89,0,0,1,1.89,1.88V9.41H15.06a1.88,1.88,0,0,1-1.88-1.88V3.76A1.88,1.88,0,0,1,15.06,1.88Z" />
        <path d="M22.59,9.41H20.71V7.53A1.88,1.88,0,0,0,22.59,9.41Z" />
        <path d="M18.82,9.41h1.89v1.88A1.89,1.89,0,0,0,18.82,9.41Z" />
      </svg>
    </div>
  );
};

export default BookingWidgetSectionHeader;
